<template>
  <div class="home">
    <el-container class="el-container">
      <el-header class="el-header" :class="header_flag == 1?'newHeader':''"><Head></Head></el-header>
      <el-main class="el-main" :class="header_flag == 1?'newMain':''"><Main></Main></el-main>
      <el-footer class="el-footer" :class="header_flag == 1?'newFooter':''"><Footer></Footer></el-footer>
    </el-container>
  </div>
</template>

<script>
import Head from "@/views/Head";
import Footer from "@/views/Footer";
import Main from "@/views/Main";

export default {
  name: "Home",
  components: {
    Head,
    Footer,
    Main,
  },
  data() {
    return {
      header_flag: 0
    };
  },
  created() {
    let header_flag = this.$route.query.header_flag
    this.header_flag = header_flag
  },
};
</script>
<style scoped>
.home {
  width: 100%;
  height: 100%;
  padding: 0px;
}
.el-container {
  position: relative;
}
.el-header {
  position: fixed;
  top: 0px;
  width: 100%;
  height: 60px;
  padding: 0px;
  margin: 0px;
  z-index: 10;
}
.el-footer {
  position: absolute;
  height: 120px !important;
  bottom: 0px;
  left: 0px;
  right: 0px;
}
.el-main {
  width: 100%;
  height: 100%;
  min-height: calc(100vh - 180px);
  padding: 0px;
  margin-bottom: 120px;
  margin-top: 60px;
}

.newHeader{
 height: 0px !important; 
 visibility: hidden;
}
.newMain{
 margin: 0px; 
}
.newFooter{
 height: 0px !important; 
 visibility: hidden;
}
</style>
