import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home.vue";

//解决产生了冗余导航，vue-router报错
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}


Vue.use(VueRouter);

const routes = [

  {
    path: "/",
    redirect: "/home"
  },
  //登录
  {
    path: "/login",
    name: "Login",
    component: () => import('@/views/login'),

  },
  //注册
  {
    path: "/register",
    name: "Register",
    component: () => import('@/views/register'),
  },
  //直播登录
  {
    path: "/livelogin",
    name: "LiveLogin",
    component: () => import('@/views/livelogin'),
  },
  //单点登录
  {
    path: "/sign_on",
    name: "sign_on",
    component: () => import('@/views/sign_on'),
  },
  //报错提醒
  {
    path: '/NotFound',
    name: "NotFound",
    component: () => import('@/views/NotFound'),
  },
  //客服
  {
    path: "/customer",
    name: "Customer",
    component: () => import('@/views/customerService'),
  },
  //首页
  {
    path: "/home",
    name: "Home",
    redirect: '/home/firstpage',
    component: Home,
    children: [{
      path: "firstpage",
      name: "Firstpage",
      component: () => import('@/views/firstpage'),
    },
    //线上课程
    {
      path: "wisdompracticeteaching",
      name: "Wisdompracticeteaching",
      redirect: '/home/wisdompracticeteaching/mainpage',
      component: () => import('@/views/wisdompracticeteaching'),
      children: [{
        path: "mainpage",
        name: "WisdompracticeteachingMainpage",
        component: () => import('@/views/wisdompracticeteaching/components/mainpage.vue'),
      },
      {
        path: "coursedetails",
        name: "Coursedetails",
        component: () => import('@/views/wisdompracticeteaching/components/coursedetails'),
      },
      {
        path: "courselearning",
        name: "Courselearning",
        component: () => import('@/views/wisdompracticeteaching/components/courselearning'),
      },
      {
        path: "answer",
        name: "Answer",
        component: () => import('@/views/wisdompracticeteaching/components/courselearning/answer'),
      },
      ]
    },
    //开放用房
    {
      path: "openroom",
      name: "Openroom",
      redirect: '/home/openroom/mainpage',
      component: () => import('@/views/openroom'),
      children: [{
        path: "mainpage",
        name: "OpenroomMainpage",
        component: () => import('@/views/openroom/components/mainpage.vue'),
      },
      {
        path: "roomdetails",
        name: "Roomdetails",
        component: () => import('@/views/openroom/components/roomdetails'),
      },
      {
        path: "roomreservation",
        name: "Roomreservation",
        component: () => import('@/views/openroom/components/roomreservation'),
      },

      ]
    },
    //虚拟仿真实验
    {
      path: "virtualsimulationexperiment",
      name: "Virtualsimulationexperiment",
      redirect: '/home/virtualsimulationexperiment/mainpage',
      component: () => import('@/views/virtualsimulationexperiment'),
      children: [{
        path: "mainpage",
        name: "VirtualsimulationexperimentMainpage",
        component: () => import('@/views/virtualsimulationexperiment/components/mainpage.vue'),
      },
      {
        path: "experimentdetails",
        name: "VirtualsimulationexperimentExperimentdetails",
        component: () => import('@/views/virtualsimulationexperiment/components/experimentdetails'),
      },
        // {
        //   path: "experimentdetailsps",
        //   name: "VirtualsimulationexperimentExperimentdetails",
        //   component: () => import('@/views/virtualsimulationexperiment/components/experimentdetailsps'),
        // },
      {
        path: "interactiveanswerreply",
        name: "VirtualsimulationexperimentInteractiveanswerreply",
        component: () => import('@/views/virtualsimulationexperiment/components/experimentdetails/components/interactiveanswerreply.vue'),
      },
      {
        path: "RemoteGuidance",
        name: "virtualsimulationexperimentRemoteGuidance",
        component: () => import('@/views/virtualsimulationexperiment/RemoteGuidance'),
      },
      ]
    },
    //演示
    {
      path: "experimentdetailsps",
      name: "experimentdetailsps",
      // redirect: '/home/virtualsimulationexperiment/mainpage',
      component: () => import('@/views/virtualsimulationexperiment/components/experimentdetailsps?header_flag=1'),
    },

    //开放实验
    {
      path: "openexperiment",
      name: "Openexperiment",
      redirect: '/home/openexperiment/mainpage',
      component: () => import('@/views/openexperiment'),
      children: [{
        path: "mainpage",
        name: "OpenexperimentMainpage",
        component: () => import('@/views/openexperiment/components/mainpage.vue'),
      },
      {
        path: "experimentdetails",
        name: "OpenexperimentExperimentdetails",
        component: () => import('@/views/openexperiment/components/experimentdetails'),
      },
      ]
    },
    //安全准入
    {
      path: "safeaccess",
      name: "Safeaccess",
      redirect: '/home/safeaccess/mainpage',
      component: () => import('@/views/safeaccess'),
      children: [{
        path: "mainpage",
        name: "SafeaccessMainpage",
        component: () => import('@/views/safeaccess/components/mainpage.vue'),
      },

      ]
    },
    //通知公告
    {
      path: "announcement",
      name: "Announcement",
      redirect: '/home/announcement/mainpage',
      component: () => import('@/views/announcement'),
      children: [{
        path: "mainpage",
        name: "AnnouncementMainpage",
        component: () => import('@/views/announcement/components/mainpage.vue'),
      },
      {
        path: "notifydetails",
        name: "AnnouncementNotifydetails",
        component: () => import('@/views/announcement/components/notifydetails.vue'),
      },
      ]
    },
    //新闻资讯
    {
      path: "news",
      name: "News",
      redirect: '/home/news/mainpage',
      component: () => import('@/views/news'),
      children: [{
        path: "mainpage",
        name: "NewsMainpage",
        component: () => import('@/views/news/components/mainpage.vue'),
      },
      {
        path: "newsdetails",
        name: "NewsNewsdetails",
        component: () => import('@/views/news/components/newsdetails.vue'),
      },
      ]
    },
    //直播
    {
      path: "livebroadcast",
      name: "LiveBroadcast",
      redirect: '/home/livebroadcast/mainpage',
      component: () => import('@/views/livebroadcast'),
      children: [{
        path: "mainpage",
        name: "LiveBroadcastMainpage",
        component: () => import('@/views/livebroadcast/components/mainpage.vue'),
      },
      // {
      //   path: "coursedetails",
      //   name: "Coursedetails",
      //   component: () => import('@/views/livebroadcast/components/coursedetails'),
      // },
      {
        path: "courselearning",
        name: "Courselearning",
        component: () => import('@/views/livebroadcast/components/courselearning'),
      },
      // {
      //   path: "answer",
      //   name: "Answer",
      //   component: () => import('@/views/livebroadcast/components/courselearning/answer'),
      // },
      ]
    },
    //个人中心
    {
      path: "personalcenter",
      name: "Personalcenter",
      redirect: '/home/personalcenter/mainpage',
      component: () => import('@/views/personalcenter'),
      children: [
        //主页面
        {
          path: "mainpage",
          name: "PersonalcenterMainpage",
          component: () => import('@/views/personalcenter/components/mainpage.vue'),
        },
        //实验报告查看
        {
          path: "experimentalreport",
          name: "Experimentalreport",
          component: () => import('@/views/personalcenter/components/virtualsimulationexperiment/components/experimentalreport.vue'),
        },

        //虚拟仿真实验报告
        {
          path: "vexperimentalreport",
          name: "Vexperimentalreport",
          component: () => import('@/views/personalcenter/components/virtualsimulationexperiment/experimentalreport.vue'),
        },
        //虚拟仿真实验报告编辑
        {
          path: "veditorreport",
          name: "Veditorreport",
          component: () => import('@/views/personalcenter/components/virtualsimulationexperiment/editorreport.vue'),
        },
        //实验报告查看
        {
          path: "experimentalreport2",
          name: "Experimentalreport2",
          component: () => import('@/views/personalcenter/components/myexperiment/components/experimentalreport.vue'),
        },
        //虚拟仿真互动问答
        {
          path: "interactiveqa",
          name: "Interactiveqa",
          component: () => import('@/views/personalcenter/components/virtualsimulationexperiment/components/interactiveqa.vue'),
        },
        //虚拟仿真互动问答详情
        {
          path: "interactiveqadetails",
          name: "Interactiveqadetails",
          component: () => import('@/views/personalcenter/components/virtualsimulationexperiment/components/interactiveqadetails.vue'),
        },
        //线上课程互动问答
        {
          path: "courseinteractiveqa",
          name: "Courseinteractiveqa",
          component: () => import('@/views/personalcenter/components/mycourses/components/interactiveqa.vue'),
        },
        //线上课程互动问答详情
        {
          path: "courseinteractiveqadetails",
          name: "Courseinteractiveqadetails",
          component: () => import('@/views/personalcenter/components/mycourses/components/interactiveqadetails.vue'),
        },
        //实验报告
        {
          path: "texperimentalreport",
          name: "texperimentalreport",
          component: () => import('@/views/personalcenter/components/teachingexperimentclass/experimentalreport.vue'),
        },
        //编写实验报告
        {
          path: "editorreport",
          name: "editorreport",
          component: () => import('@/views/personalcenter/components/teachingexperimentclass/editorreport.vue'),
        },
        //线上课成绩
        {
          path: "courseresults",
          name: "Courseresults",
          component: () => import('@/views/personalcenter/components/mycourses/components/courseresults.vue'),
        },
        //虚拟仿真实验证书
        {
          path: "experimentcertificate",
          name: "Experimentcertificate",
          component: () => import('@/views/personalcenter/components/virtualsimulationexperiment/components/experimentcertificate.vue'),
        },
        //实验预习资料
        {
          path: "previewmaterialdetails",
          name: "Previewmaterialdetails",
          component: () => import('@/views/personalcenter/components/teachingexperimentclass/previewmaterialdetails.vue'),
        },
        //实验预习考试
        {
          path: "previewexam",
          name: "Previewexam",
          component: () => import('@/views/personalcenter/components/myexperiment/components/previewexam.vue'),
        },
        //实验预习考试-答题
        {
          path: "answer",
          name: "Answer",
          component: () => import('@/views/personalcenter/components/myexperiment/components/answer.vue'),
        },
        //实习报告
        {
          path: "internshipreport",
          name: "Internshipreport",
          component: () => import('@/views/personalcenter/components/myinternship/components/internshipreport.vue'),
        },
        //实习报告查看
        {
          path: "internshipreportview",
          name: "Internshipreportview",
          component: () => import('@/views/personalcenter/components/myinternship/components/internshipreportview.vue'),
        },
        //提交实习报告
        {
          path: "submitreport",
          name: "Submitreport",
          component: () => import('@/views/personalcenter/components/myinternship/components/submitreport.vue'),
        },
        //成绩查看
        {
          path: "internshipresultsview",
          name: "Internshipresultsview",
          component: () => import('@/views/personalcenter/components/myinternship/components/internshipresultsview.vue'),
        },
        //实习报名
        {
          path: "registrationname",
          name: "Registrationname",
          component: () => import('@/views/personalcenter/components/myinternship/components/registrationname.vue'),
        },
        //编辑实习报名(重报)
        {
          path: "editorregistrationname",
          name: "Editorregistrationname",
          component: () => import('@/views/personalcenter/components/myinternship/components/editorregistrationname.vue'),
        },
        //实习详情
        {
          path: "internshipdetails",
          name: "Internshipdetails",
          component: () => import('@/views/personalcenter/components/myinternship/components/internshipdetails.vue'),
        },
        //日志
        {
          path: "log",
          name: "Log",
          component: () => import('@/views/personalcenter/components/myinternship/components/log.vue'),
        },
        //新增/编辑日志
        {
          path: "editorlog",
          name: "Editorlog",
          component: () => import('@/views/personalcenter/components/myinternship/components/editorlog.vue'),
        },
        //我的请假
        {
          path: "myleave",
          name: "Myleave",
          component: () => import('@/views/personalcenter/components/myinternship/components/myleave.vue'),
        },
        //新建请假
        {
          path: "newleave",
          name: "Newleave",
          component: () => import('@/views/personalcenter/components/myinternship/components/newleave.vue'),
        },
        //签到
        {
          path: "signin",
          name: "Signin",
          component: () => import('@/views/personalcenter/components/myinternship/components/signin.vue'),
        },
      ]
    },

    ]
  },


];

const router = new VueRouter({
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    }
    return {
      x: 0,
      y: 0
    }
  }
});

export default router;
