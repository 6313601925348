import Vue from "vue";
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import App from "./App.vue";
import router from "./router";
import store from "./store";
import VueScrollTo from 'vue-scrollto'
import './styles/style.scss'
import './icons/index' //svgicon公共组件化

// 视频播放
import VideoPlayer from "vue-video-player";
import "video.js/dist/video-js.css"; //videoJs的样式
import "vue-video-player/src/custom-theme.css"; //vue-video-player的样式
import "videojs-flash"; //引入才能播放rtmp视屏
import "videojs-contrib-hls"; //引入才能播放m3u8文件
Vue.use(VideoPlayer);

Vue.use(ElementUI);
Vue.use(VueScrollTo);
//  window.xx='哈哈哈'
// import 'lib-flexible'
Vue.config.productionTip = false;
Vue.prototype.my_router=router;
new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
