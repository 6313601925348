<template>
  <div class="footer">
    <div class="item">
      地址：武汉市东湖新技术开发区华师园路5号华师科技园2栋204 邮编：437100 电话：027—81815968
      <!-- 传真：027-87384670 -->
      
    </div>
    <div class="item">
      Copyright ©2013-2019 武汉有教文化传播有限公司 版权所有 技术支持：武汉有教文化传播有限公司
    </div>
    <div class="item">
      <a href="https://beian.miit.gov.cn/#/Integrated/index" target="_blank" style="color:aliceblue">
         鄂ICP备19003195号-2
      </a>
      <!-- <span class="foot_icon"></span> 鄂公网安备
      42011102000808号 -->
    </div>
  </div>
</template>

<script>
export default {
  name: "Footer",
  components: {},
  data() {
    return {};
  },
};
</script>
<style scoped lang="scss">
.footer {
  width: 100%;
  height: 100%;
  background: #111;
  margin: 0 auto;
  position: absolute;
  bottom: 0;
  left: 0;
  .item {
    font-size: 15px;
    font-weight: 400;
    color: #ffffff;
    text-align: center;
    padding-top: 10px;
    .foot_icon {
      display: inline-block;
      width: 20px;
      height: 20px;
      background: url("../assets/footicon.png") 0 0 no-repeat;
      background-size: 100% 100%;
    }
  }
}
</style>
