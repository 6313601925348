import Vue from "vue";
import Vuex from "vuex";
import {
  getToken,
  setToken,
  removeToken,
  getIdentifierToken,
  setIdentifierToken,
  removeIdentifierToken,
  getWebNameToken,
  setWebNameToken,
  removeWebNameToken,
  getWebAvaterToken,
  setWebAvaterToken,
  removeWebAvaterToken,
} from "@/utils/auth";
import { login, logout } from "@/api/login";
import { getuserinfo } from "@/api/user";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    username: getWebNameToken(),
    useravater: getWebAvaterToken(),
    identifier: getIdentifierToken(),
    token: getToken(),
    menuactiveid: window.localStorage.getItem("menuId")
      ? parseInt(window.localStorage.getItem("menuId"))
      : 0,
    noticeFlag: true,
  },
  mutations: {
    SET_MENU_ID: (state, id) => {
      state.menuactiveid = id;
    },
    SET_TOKEN: (state, token) => {
      state.token = token;
    },
    SET_Identifier: (state, token) => {
      state.identifier = token;
    },

    SET_Username: (state, token) => {
      state.username = token;
    },

    SET_Useravater: (state, token) => {
      state.useravater = token;
    },
    changeNoticeFlag: (state, bool) => {
      state.noticeFlag = bool;
    },
  },
  actions: {
    setmenuid({ commit }, id) {
      commit("SET_MENU_ID", id);
    },

    // 用户登录 获取token
    login({ commit }, userInfo) {
      const { username, password } = userInfo;
      //清空缓存
      //重置按钮id
      commit("SET_MENU_ID", 0);
      window.localStorage.removeItem("menuId");
      //清除token
      commit("SET_TOKEN", "");
      removeToken();

      //清除用户身份信息
      commit("SET_Identifier", "");
      removeIdentifierToken();
      // dispatch('tagsView/delAllViews', null, {
      //   root: true
      // })
      //清除用户名
      commit("SET_Username", "");
      removeWebNameToken();
      //清除头像
      commit("SET_Useravater", "");
      removeWebAvaterToken();

      return new Promise((resolve, reject) => {
        login({
          mobile: username,
          password: password,
        })
          .then((response) => {
            // code：408-用户手机号错误
            const { data } = response;

            //重置按钮id
            commit("SET_MENU_ID", 0);
            window.localStorage.setItem("menuId", 0);

            commit("SET_TOKEN", data.access_token); // 登录成功保存token到vuex全局state
            setToken(data.access_token); // 登录成功保存token到cookie 字段为Admin-Token

            commit("SET_Identifier", data.user_identity); //获取用户身份信息
            setIdentifierToken(data.user_identity);

            resolve();
          })
          .catch((error) => {
            reject(error);
          });
      });
    },

    // 用户退出清除token及其他信息
    logout({ commit, state, dispatch }) {
      return new Promise((resolve, reject) => {
        logout()
          .then(() => {
            //重置按钮id
            commit("SET_MENU_ID", 0);
            window.localStorage.removeItem("menuId");
            //清除token
            commit("SET_TOKEN", "");
            removeToken();

            //清除用户身份信息
            commit("SET_Identifier", "");
            removeIdentifierToken();
            // dispatch('tagsView/delAllViews', null, {
            //   root: true
            // })
            removeWebNameToken();
            removeWebAvaterToken();

            resolve();
          })
          .catch((error) => {
            reject(error);
          });
      });
    },

    //获取用户信息
    getuserinfo(params) {
      getuserinfo(params)
        .then((response) => {
          if (response.code == 0) {
            commit("SET_Username", response.data.name);
            setWebNameToken(response.data.name);

            commit("SET_Useravater", response.data.avatar);
            setWebAvaterToken(response.data.avatar);
          }
        })
        .catch((error) => {});
    },
  },
  modules: {},
});
